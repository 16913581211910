import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {TranslocoModule} from '@jsverse/transloco';
import {PanelError as PanelError2, PanelStatus as PanelStatus2} from 'api/entities';
import {switchMap} from 'rxjs';
import {filter} from 'rxjs/operators';
import {PanelError, PanelStatus} from 'types';
import {MessageComponent} from '../../../shared/components/message/message.component';
import {PanelQueryParams} from '../../../shared/constants/query-params.constant';
import {UserSessionService} from '../../../shared/entities/auth/user-session.service';
import {DeviceActivationEntity} from '../../../shared/entities/devices/device-activation.entity';
import {SystemEntity} from '../../../shared/entities/system/system.entity';

@Component({
  selector: 'alc-panel-server-status',
  standalone: true,
  imports: [MatProgressBarModule, MessageComponent, TranslocoModule, MatIconModule, MatButtonModule],
  templateUrl: './panel-server-status.component.html',
  styleUrls: ['./panel-server-status.component.scss']
})
export class PanelServerStatusComponent implements OnInit, OnChanges {
  PanelStatus = PanelStatus;
  PanelError = PanelError;
  hasNonCriticalErrors = false;
  baseSystemNavigationUrl: string;
  isPanel: boolean;
  private baseSystemUrl: string;
  @Input() panelStatus: PanelStatus | PanelStatus2;
  @Input() panelErrors: (PanelError | PanelError2)[];

  constructor(
    private deviceActivationEntity: DeviceActivationEntity,
    private systemEntity: SystemEntity
  ) {}

  ngOnInit(): void {
    this.systemEntity
      .getStaticInfo()
      .pipe(
        filter(response => (this.isPanel = response.deploymentType && UserSessionService.isOnSitePanel(response.deploymentType))),
        switchMap(() => this.deviceActivationEntity.panelInfo())
      )
      .subscribe(response => {
        this.baseSystemUrl = response.baseSystemUrl;
        const url = new URL(
          `/client/${response.clientId}/devices/onSitePanelServer/${response.deviceId}/configuration?${PanelQueryParams.isPanel}=true&${
            PanelQueryParams.panelUrl
          }=${encodeURIComponent(location.origin)}`,
          this.baseSystemUrl
        );
        this.baseSystemNavigationUrl = url.href;
      });
  }

  ngOnChanges(): void {
    this.hasNonCriticalErrors =
      this.panelErrors?.includes(PanelError.FlywayVersionMismatch) ||
      this.panelErrors?.includes(PanelError.BeetleSync) ||
      this.panelErrors?.includes(PanelError.DataPointFlagCalculate);
  }
}
