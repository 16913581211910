@if (panelStatus === PanelStatus.Unregistered) {
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_UNREGISTERED' | transloco"></p>
  </alc-message>
} @else if (panelStatus === PanelStatus.Registered) {
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_REGISTERED' | transloco"></p>
  </alc-message>
} @else if (panelStatus === PanelStatus.Ready) {
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.READY' | transloco"></p>
  </alc-message>
} @else if (panelStatus === PanelStatus.Integrated) {
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_INTEGRATED' | transloco"></p>
  </alc-message>
} @else if (panelStatus === PanelStatus.Activated) {
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_ACTIVATED' | transloco"></p>
  </alc-message>
} @else if (panelStatus === PanelStatus.Importing) {
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.IMPORTING_IN_PROGRESS' | transloco"></p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </alc-message>
} @else if (panelStatus === PanelStatus.Unlinking) {
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.UNLINKING_IN_PROGRESS' | transloco"></p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </alc-message>
} @else if (panelStatus === PanelStatus.Error) {
  <alc-message class="error" icon="error">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.IMPORTING_ERROR' | transloco"></p>
    @for (error of panelErrors; track error) {
      @if (error === PanelError.BeetleSync) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.BEETLE_SYNC" | transloco }}</span>
      } @else if (error === PanelError.DatabaseImport) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.DATABASE_IMPORT" | transloco }}</span>
      } @else if (error === PanelError.FlywayVersionMismatch) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.FLYWAY_VERSION_MISMATCH" | transloco }}</span>
      } @else if (error === PanelError.General) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.GENERAL" | transloco }}</span>
      } @else if (error === PanelError.RestoreFailed) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.RESTORE_FAILED" | transloco }}</span>
      } @else if (error === PanelError.DataPointFlagCalculate) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.DATA_POINT_FLAG" | transloco }}</span>
      }
    }
    @if (isPanel) {
      <span>{{ "DEVICE_REGISTRATION.MESSAGE.LINK_BASE_SYSTEM_SYNC_MANUALLY" | transloco }} </span><br />
      <a [href]="baseSystemNavigationUrl" target="_blank" rel="noreferrer" mat-flat-button>
        <mat-icon>open_in_new</mat-icon>
        {{ "DEVICE_REGISTRATION.LABEL.LINK_BASE_SYSTEM_SYNC_MANUALLY" | transloco }}</a
      >
    }
  </alc-message>
}
@if (hasNonCriticalErrors && panelStatus !== PanelStatus.Error) {
  <alc-message class="warning" icon="warning">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.IMPORTING_ERROR' | transloco"></p>
    @for (error of panelErrors; track error) {
      @if (error === PanelError.BeetleSync) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.BEETLE_SYNC" | transloco }}</span>
      } @else if (error === PanelError.FlywayVersionMismatch) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.FLYWAY_VERSION_MISMATCH" | transloco }}</span>
      } @else if (error === PanelError.DataPointFlagCalculate) {
        <span>{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.DATA_POINT_FLAG" | transloco }}</span>
      }
    }
  </alc-message>
}
